import React, {useContext} from "react";
import {Header} from "../components/Header";
import {
    Flex,
    Text,
    Card,
    RadioGroupField,
    Radio,
    useTheme,
    Loader,
    Alert,
    Divider
} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";
import {CartDispatchContext, clearCart} from "../contexts/cart";
import {DatasourceDispatchContext, DatasourceStateContext, selectDataSource} from "../contexts/datasource";
import {PreviousOrders} from "../components/PreviousOrders";
import {NewButton} from "../components/Buttons";
import {Main} from "../components/Main";
import {getDataSourceLabel} from '../helpers/dataSourceLabels';


// Select data source
// Create an order

export const StartPage = () => {

    // @ts-ignore
    const {metadata, isLoaded, datasource} = useContext(DatasourceStateContext)
    const dispatch = useContext(CartDispatchContext)
    const dataSourceDispatch = useContext(DatasourceDispatchContext)
    const navigate = useNavigate();

    const {tokens} = useTheme();

    const dataSources = () => {
        return metadata.map((source: any) => source.name);
    }

    const newQuery = () => {
        clearCart(dispatch, datasource.name, datasource.measure)
        navigate('/order');
    }

    // @ts-ignore
    const chooseDataSource = (event) => {
        selectDataSource(dataSourceDispatch, event.target.value)
    }


    return (
        <Flex
            minHeight={"100vh"}
            direction={"column"}
            gap={"0em"}
        >
            <Header/>
            <Alert role="none">This app is a work in progress by the DA Innovation team,
                and as such features and layouts may change unexpectedly and without warning.
                Saved data may also not be preserved.
            </Alert>
            <Main>
                <Flex direction={"column"} gap={tokens.space.small} width={'100%'}>
                    {
                        !isLoaded &&
                        <Card>
                            <Flex direction={'row'}>
                                <Loader size={'large'}/>
                                <Text>Loading available data sources...</Text>
                            </Flex>
                        </Card>

                    }
                    {
                        isLoaded &&
                        <Card>
                            <Flex direction={'row'} justifyContent={'space-between'}>
                                <Text>Create a new order</Text>
                                <p/>
                            </Flex>
                            <Divider/>
                            <Flex direction={"column"} alignItems={"flex-start"}>
                                <p/>
                                <RadioGroupField
                                    onChange={chooseDataSource}
                                    label="Select a data source:"
                                    name="dataSource"
                                    value={datasource.name}
                                >
                                    {dataSources().map((dataSource: any) => {
                                        return (
                                            <Radio key={dataSource} value={dataSource}>
                                                {getDataSourceLabel(dataSource)}
                                            </Radio>
                                        )
                                    })}
                                </RadioGroupField>
                                <p/>

                                <NewButton action={newQuery} label={"Create new order"}/>
                            </Flex>
                        </Card>
                    }

                    <Card>
                        <Flex direction={'row'} justifyContent={'space-between'}>
                            <Text>Notes on Datasets</Text>
                            <p/>
                        </Flex>
                        <Divider/>
                        <p/>
                        <Text fontSize={"small"}> Student Record only contains students within the standard registration population (i.e. <a href="https://www.hesa.ac.uk/collection/c17054/derived/xpsr01" target="_blank" rel="noopener noreferrer">XPSR01</a> = 1) </Text>
                        <Text fontSize={"small"}> Qualifiers Record only contains the qualifications obtained population (i.e. <a href="https://www.hesa.ac.uk/collection/c17051/derived/xpqual01" target="_blank" rel="noopener noreferrer">XPQUAL01</a> = 1) </Text>
                        <Text fontSize={"small"}> Students and Qualifiers Record can be used to define other populations using these markers (Student population marker <a href="https://www.hesa.ac.uk/collection/c17054/derived/xpsr01" target="_blank" rel="noopener noreferrer">(XPSR01)</a> and Qualifiers population marker <a href="https://www.hesa.ac.uk/collection/c17051/derived/xpqual01" target="_blank" rel="noopener noreferrer">(XPQUAL01)</a>) </Text>
                        <Text fontSize={"small"}> Graduate Outcomes and Student Record can be used to define graduate survey completed data (i.e. <a href="https://www.hesa.ac.uk/collection/c17072/derived/zrespstatus" target="_blank" rel="noopener noreferrer">zrespstatus</a> = '04', <a href="https://www.hesa.ac.uk/collection/c17072/derived/xactivity" target="_blank" rel="noopener noreferrer">xactivity</a> ≠ '99' and <a href="https://www.hesa.ac.uk/collection/c17051/derived/xpgo01" target="_blank" rel="noopener noreferrer">xpg01</a> = 1) </Text>
                        <Text fontSize={"small"}> If a subject split is not included in the order then FPE = Count of students </Text>
                    </Card>


                    <Card minWidth={'100%'}>
                        <PreviousOrders type={'savedOrders'} title={'Saved orders'}/>
                    </Card>
                    <Card minWidth={'100%'}>
                        <PreviousOrders title={'Completed orders'}/>
                    </Card>
                </Flex>
            </Main>
        </Flex>
    )
}

export default StartPage;